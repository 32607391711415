import AuthRepository from './modules/authRepository';
import emailRepository from './modules/emailRepository';
import userRepository from './modules/usersRepository';
import salesProspecting from './modules/salesProspectingRepository';
import scoreCard from './modules/scoreCardRepository';
import bsp from './modules/bspRepository';
import reportsGeneration from './modules/reportsRepository';
import isRepository from './modules/isRepository';
import merchantRepository from './modules/merchantRepository';
import accountManagementRepository from './modules/accountManagementRepository';
import financeRepository from './modules/financeRepository';
import appHistoryRepository from './modules/appHistoryRepository';

const repositories = {
	auth: AuthRepository,
	users: userRepository,
	email: emailRepository,
	salesProspecting: salesProspecting,
	scoreCard: scoreCard,
	bsp:bsp,
	reportsGeneration:reportsGeneration,
	isRepository:isRepository,
	merchantRepository: merchantRepository,
	accountManagementRepository,
	financeRepository,
	appHistoryRepository
};


export const RepositoryFactory = {
	get: name => repositories[name]
};