import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
// import router from '../../../router';
// import Vue from 'vue';

const appHistoryRepository = RepositoryFactory.get('appHistoryRepository');
//Get Comments using API
const doGetComments = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		appHistoryRepository.getappHistoryComments(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
        console.log(response.data.result)
        commit('SET_COMMENTS', response.data.result)
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});


export default{
	doGetComments,
};