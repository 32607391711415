import Repository from '../Repository';
import axios from 'axios';

export default {
	getAllSalesProspecting () {
		return Repository.get('api/sales-prospecting');
	},
	getAllAllowedBsp () {
		return Repository.get('api/bsp/allowed-bsp');
	},
	getAllListSalesProspecting () {
		return Repository.get('api/sales-prospecting/list');
	},
	getAllTerminationPending () {
		return Repository.get('api/admin/sales-prospecting/termination');
	},
	createSPNumber (payload) {
		return Repository.post('/api/encoder/sales-prospecting', payload);
	},
	saveSalesProspecting ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/' + payload.id, payload.payload);
	},
	updateExistingSalesProspecting (payload) {
		return Repository.post('/api/encoder/sales-prospecting/submit-existing-update/' + payload);
	},

	reAccreditationSaveSalesProspecting (payload) {
		return Repository.post('/api/encoder/sales-prospecting/submit-reaccreditation/' + payload);
	},

	getSalesProspecting (payload) {
		return Repository.get('/api/encoder/sales-prospecting/all/' + payload);
	},
	uploadFiles ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/location-upload/' + payload.id, payload.payload)
	},
	uploadOwner ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/details-owner/' + payload.id, payload.payload)
	},
	getUploadedFiles (payload) {
		return Repository.get('/api/encoder/sales-prospecting/uploaded/' + payload)
	},
	retrieveUploaded (payload) {
		return Repository.post('/api/retrieve-gcp', payload)
	},
	approveSPByEval (payload) {
		return Repository.put('/api/approver/approve-evaluation/' + payload)
	},
	approveSPByPres (payload) {
		return Repository.put('/api/approver/approve-ceo/' + payload)
	},
	approveSPByOnboarding ({payload}) {
		return Repository.put('/api/approver/approve-onboarding/' + payload.id, payload.payload)
	},
	approveSPByValidation ({payload}) {
		return Repository.put('/api/approver/approve-validation/' + payload.id, payload.payload)
	},
	// getCities () {
	// 	return axios.get(process.env.VUE_APP_USP_MAINTENANCE_URL + '/v1/maintenance/api/maintenance/city', {
	// 		headers: {
	// 			'X-Perahub-Gateway-Token': 'MWhkYWoydW5kZGFubl4ldWRhczs0NDQ='
	// 		}
	// 	});
	// },
	getCountries () {
		return axios.get(process.env.VUE_APP_USP_MAINTENANCE_URL + '/v1/maintenance/api/collections/countries', {
			headers: {
				'X-Perahub-Gateway-Token': 'MWhkYWoydW5kZGFubl4ldWRhczs0NDQ='
			}
		});
	},
	updateSalesProspecting ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/details/' + payload.id, payload.payload);
	},
	updateBusinessInformation ({payload}) {
		return Repository.put('/api/encoder/sales-prospecting/business-info/' + payload.id, payload.payload);
	},
	updateOwner ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/details-owner/update/' + payload.id, payload.payload);
	},
	initialUpload ({payload}) {
		return Repository.post('api/encoder/sales-prospecting/upload/' + payload.id, payload.payload)
	},
	uploadBranch ({payload}) {
		return Repository.post('api/encoder/sales-prospecting/details-branch/' + payload.id, payload.payload)
	},
	updateBranch ({payload}) {
		return Repository.post('api/encoder/sales-prospecting/details-branch/update/' + payload.id, payload.payload)
	},
	createComment_Eva ({payload}) {
		return Repository.put('api/approver/approve-evaluation/' + payload.id, payload.payload)
	},
	createComment_Ceo ({payload}) {
		return Repository.put('api/approver/approve-ceo/' + payload.id, payload.payload)
	},
	uploadBSP ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/bsp/' + payload.id, payload.payload)
	},
	generateMoa ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/generate/' + payload.id, payload.payload)
	},
	// getProvince () {
	// 	return Repository.get('/api/provinces')
	// },
	getRegion (payload) {
		return Repository.get('/api/provinces/region/' + payload)
	},
	getCities (payload) {
		return Repository.get('/api/provinces/cities/' + payload)
	},

	uploadBod ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/details-bod/' + payload.id, payload.payload)
	},

	updateBod ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/details-bod/update/' + payload.id, payload.payload);
	},

	//UPDATING OF DOCUMENTS IN REACCREDITATION
	updateComplianceDocuments ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/upload-compliance-documents/' + payload.id, payload.payload);
	},
	updatePrimaryContactInformationDocuments ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/upload-contact-documents/' + payload.id, payload.payload);
	},
	uploadRiskTool ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/upload-assessment/' + payload.id, payload.payload);
	},

	getAddress(payload, token) {
        return axios.get(process.env.VUE_APP_HRIS_BASE_URL + '/api/backend.region-province-city-by-zipcode/' + payload, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });
    },

	getUploadedRenewableFiles (payload) {
		return Repository.get('/api/encoder/sales-prospecting/uploaded-renew/' + payload)
	},

	updateRenewableDocs ({payload}) {
		return Repository.post('api/encoder/sales-prospecting/uploaded-renew/' + payload.id, payload.payload)
	},

	deleteBod ({payload}) {
		return Repository.delete('/api/encoder/sales-prospecting/details-bod/delete/' + payload.id, {
			headers: {
				'content-type': ' application/json'
			},
			data: {
				"bod_id": payload.bod_id,
			}}
		)
	},

	deleteBranch ({payload}) {
		return Repository.delete('/api/encoder/sales-prospecting/details-branch/delete/' + payload.id, {
			headers: {
				'content-type': ' application/json'
			},
			data: {
				"branch_id": payload.branch_id,
			}}
		)
	},

	deleteFla ({payload}) {
		return Repository.delete('/api/encoder/sales-prospecting/details-branch/delete/' + payload.id, {
			headers: {
				'content-type': ' application/json'
			},
			data: {
				"fla_id": payload.fla_id
			}}
		)
	},

	deleteOwner ({payload}) {
		return Repository.delete('/api/encoder/sales-prospecting/details-owner/delete/' + payload.id, {
			headers: {
				'content-type': ' application/json'
			},
			data: {
				"owner_id": payload.owner_id
			}}
		)
	},

	returnValidation ({payload}) {
		return Repository.put('api/approver/reject-validation/' + payload.id, payload.payload)
	},

	returnOnboarding ({payload}) {
		return Repository.put('api/approver/reject-onboarding/' + payload.id, payload.payload)
	},

	getEmailTriggerSched () {
		return Repository.get('api/admin/trigger-sched/');
	},

	updateEmailTrigger (payload) {
		return Repository.put('api/admin/trigger-sched/', payload);
	},

	uploadWitness ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/witness/' + payload.id, payload.payload)
	},

	uploadSignatory ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/add-signatory/' + payload.id, payload.payload)
	},

	updateSignatory ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/update-signatory/' + payload.id, payload.payload)
	},

	deleteWitness ({payload}) {
		return Repository.delete('/api/encoder/sales-prospecting/delete-signatory/' + payload.id, {
			headers: {
				'content-type': ' application/json'
			},
			data: {
				"signatory_id": payload.signatory_id
			}}
		)
	},

	getProvince(token) {
        return axios.get(process.env.VUE_APP_HRIS_BASE_URL + '/api/backend.region-province-list/', {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });
    },

	getProvinceDetails(token) {
        return axios.get(process.env.VUE_APP_HRIS_BASE_URL + '/api/backend.region-province-city-list/', {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });
    },

	getCity(payload, token) {
        return axios.get(process.env.VUE_APP_HRIS_BASE_URL + '/api/backend.region-by-name/' + payload, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });
    },

	uploadAvp ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/update/' + payload.id, payload.payload)
	},

	returnSp_Eva ({payload}) {
		return Repository.put('api/approver/reject-evaluation/' + payload.id, payload.payload)
	},

	disapproveSPByPresident ({payload}) {
		return Repository.put('api/approver/disapprove-president/' + payload.id, payload.payload)
	},

	getlogs () {
		return Repository.get('api/admin/error-logs');
	},
	fileTermination ({payload}) {
		return Repository.post('api/encoder/file-termination/' + payload.id, payload.finalPayload);
	},
	approveTermination (payload) {
		return Repository.delete('api/admin/sales-prospecting/delete/'+ payload);
	},
	rejectTermination (payload) {
		return Repository.get('api/admin/sales-prospecting/disapprove-deletion/'+ payload);
	},
	requestCmap ({payload}) {
		return Repository.post('api/encoder/sales-prospecting/request-cmap/'+ payload.id, payload.finalPayload);
	},
	getDashboardData () {
		return Repository.get('/api/admin/dashboard/faq-stat-acro/');
	},
	updateFaq ({payload}) {
		return Repository.put('/api/admin/dashboard/faq/'+ payload.id, payload.finalPayload);
	},
	updateAcronym ({payload}) {
		return Repository.put('/api/admin/dashboard/acro/'+ payload.id, payload.finalPayload);
	},
	updateStatus ({payload}) {
		return Repository.put('/api/admin/dashboard/stat/'+ payload.id, payload.finalPayload);
	},
	addFaq ({payload}) {
		return Repository.post('/api/admin/dashboard/faq', payload);
	},
	addStatus ({payload}) {
		return Repository.post('/api/admin/dashboard/stat', payload);
	},
	addAcronym ({payload}) {
		return Repository.post('/api/admin/dashboard/acro', payload);
	},
	deleteItem (payload) {
		return Repository.delete('/api/admin/dashboard/faq/'+ payload);
	},
	deleteStatus (payload) {
		return Repository.delete('/api/admin/dashboard/stat/'+ payload);
	},
	deleteAcronym (payload) {
		return Repository.delete('/api/admin/dashboard/acro/'+ payload);
	},
	getAllUsers () {
		return Repository.get('/api/admin/user');
	},
	addCluserHead (payload) {
		return Repository.post('/api/cluster/head', payload);
	},
	getAllCluster () {
		return Repository.get('/api/cluster/details');
	},
	addRoh ({payload}) {
		return Repository.post('/api/cluster/roh/'+ payload.id , payload.finalPayload);
	},
	addSas ({payload}) {
		return Repository.post('/api/cluster/sas/'+ payload.id , payload.finalPayload);
	},
	deleteSas ({payload}) {
		return Repository.delete('/api/cluster/sas/' + payload.id, {
			headers: {
				'content-type': ' application/json'
			},
			data: {
				"roh_id": payload.finalPayload.roh_id,
				"sas_id": payload.finalPayload.sas_id
			}}
		)
	},
	deleteRoh ({payload}) {
		return Repository.delete('/api/cluster/roh/' + payload.id, {
			headers: {
				'content-type': ' application/json'
			},
			data: {
				"roh_id": payload.finalPayload.roh_id,
			}}
		)
	},
	deleteCluster(payload){
		return Repository.delete('/api/cluster/head/'+payload)
	},
	getDashboard () {
		return Repository.get('/api/admin/dashboard/details');
	},
	getProfilingData () {
		return Repository.get('/api/profiling');
	},
	getNotHandledBranch () {
		return Repository.get('/api/profiling/branch-handler');
	},
	addHandledEntity ({payload}) {
		return Repository.post('/api/profiling/encoded/handler/' + payload.id, payload.finalPayload)
	},
	deleteHandledEntity(payload){
		return Repository.put('/api/profiling/encoded/handler/' + payload)
	},
	addNewEntity ({payload}) {
		return Repository.post('/api/profiling/not-encoded/handler', payload)
	},
	deleteNewHandledEntity(payload){
		return Repository.delete('/api/profiling/not-encoded/handler/' + payload)
	},
	editNewEntity ({payload}) {
		return Repository.put('/api/profiling/not-encoded/handler/' + payload.id, payload.finalPayload)
	},
	uploadProfile ({payload}) {
		return Repository.post('/api/encoder/sales-prospecting/profile-picture/' + payload.id, payload.payload)
	},
	pageBranch ({payload}) {
		return Repository.get('/api/encoder/sales-prospecting/all/' + payload.id + '?page=' + payload.page)
	},
	getStatusCode (payload) {
		return Repository.post('/api/fix/status-code', payload)
	},
	getAllMerchantDetails (payload) {
		return Repository.get('/api/merchant/all-merchant/' + payload);
	},
	getEnrolledDetails (payload) {
		return Repository.get('/api/merchant/enrolled-merchant/' + payload);
	},
	pageEnrolledBranch ({payload}) {
		return Repository.get('/api/merchant/enrolled-merchant/' + payload.id + '?page=' + payload.page)
	},
	getUnenrolledDetails (payload) {
		return Repository.get('/api/merchant/unenrolled-merchant/' + payload);
	},
	pageUnenrolledBranch ({payload}) {
		return Repository.get('/api/merchant/unenrolled-merchant/' + payload.id + '?page=' + payload.page)
	},
	uploadSasEntity ({payload}) {
        return Repository.post('/api/import/update-entity', payload.payload)
    },
	uploadSasLocation ({payload}) {
        return Repository.post('/api/import/update-location', payload.payload)
    },
	getLocationEntity () {
		return Repository.get('api/activation/activated-list');
	},
	getSuspensionForApproval () {
		return Repository.get('api/activation/suspension');
	},
	fileSuspensionEntity ({payload}) {
		return Repository.post('/api/activation/suspension/req-entity/' + payload.id, payload.payload)
	},
	fileSuspensionLocation ({payload}) {
		return Repository.post('/api/activation/suspension/req-branch/' + payload.id, payload.payload)
	},
	fileDeactivationEntity ({payload}) {
		return Repository.post('/api/activation/deactivation/req-entity/' + payload.id, payload.payload)
	},
	fileDeactivationLocation({payload}) {
		return Repository.post('/api/activation/deactivation/req-branch/' + payload.id, payload.payload)
	},
	getDeactivationForApproval () {
		return Repository.get('api/activation/deactivation');
	},
	disapproveEntity ({payload}) {
		return Repository.post('api/activation/disapproval/entity/' + payload.id, payload.payload);
	},
	disapproveLocation ({payload}) {
		return Repository.post('api/activation/disapproval/branch/' + payload.id, payload.payload);
	},
	approveEntity (payload) {
		return Repository.get('api/activation/approval/entity/' + payload);
	},
	approveLocation (payload) {
		return Repository.get('api/activation/approval/branch/' + payload);
	},
	getReactivationList () {
		return Repository.get('api/activation/reactivation/');
	},
	fileReactivationEntity ({payload}) {
		return Repository.post('api/activation/reactivation/req-entity/' + payload.id, payload.payload);
	},
	fileReactivationLocation ({payload}) {
		return Repository.post('api/activation/reactivation/req-branch/' + payload.id, payload.payload);
	},
	getArchivedList () {
		return Repository.get('api/activation/archived');
	},
	revertStatus (payload) {
		return Repository.get('api/admin/force-return/' + payload);
	},
};