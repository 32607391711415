const SET_PRIMARY_DETAILS = (state, payload) => {
    state.primaryDetails = payload
};

const SET_COMPLIANCE_OFFICER = (state, payload) => {
    state.complianceOfficer = payload
};

const SET_COMPLIANCE_OFFICER_DEFAULT = (state) => {
    state.complianceOfficer = {
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        region: '',
        country: 'Philippines',
        province:'',
        postal_code: '',
        email_address: '',
        landline: '',
        cellphone: '',
        is_same_primary: null,
        amla_certificate: {
            file: null,
        },
        valid_id: {
            file: null,
        },
    }
};

const SET_PRIMARY_BUSINESS_ADDRESS = (state, payload) => {
    state.primaryBusinessAddress = payload
};


const SET_PRIMARY_BUSINESS_ADDRESS_DEFAULT = (state) => {
    state.primaryBusinessAddress = {
        address_line_1: '',
        address_line_2: '',
        city: '',
        region: '',
        country: 'Philippines',
        province:'',
        postal_code: '',
        email_address: '',
        landline: '',
        cellphone: '',
        is_same_primary: null,
        mailing_address_line_1: '',
        mailing_address_line_2: '',
        mailing_city: '',
        mailing_region: '',
        mailing_country: 'Philippines',
        mailing_postal_code: '',
        mailing_province: '',
        mailing_designated_receiver: '',
        mailing_contact_number: ''
    }
}

const SET_PRIMARY_CONTACT_INFO = (state, payload) => {
    state.primaryContactInfo = payload
};

const SET_PRIMARY_CONTACT_INFO_DEFAULT = (state) => {
    state.primaryContactInfo = {
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        province:'',
        region: '',
        country: 'Philippines',
        postal_code: '',
        email_address: '',
        landline: '',
        cellphone: '',
        is_same_primary: null,
        amla_certificate: {
            file: null,
        },
        valid_id: {
            file: null,
        },

    }
};

const SET_OTHER_PRIMARY_DETAILS = (state, payload) => {
    state.otherPrimaryDetails = payload
}

const SET_OTHER_PRIMARY_DETAILS_DEFAULT = (state) => {
    state.otherPrimaryDetails = {
        type: '',
        bsp_date_of_issue: '',
        bsp_rsa_number: '',
        business_information: '',
        business_type_of_sub_agents: '',
        company_registration_number: '',
        government_tax_id: '',
        is_kya_certified: false,
        kya_date_next_cert: '',
        list_of_avp_products: '',
        list_of_non_ex_remco: '',
        is_western_union: false,
        state_of_registration: 'Philippines',
        top_reasons_for_WU: '',
        type_of_entity: '',
        type_of_registration: '',
        typical_customers: '',
        years_in_business: '',
        single_location: null,
        mother_location: '',
        class_code_entity:'',
        company_id: '',
        is_rsa: false,
        is_tie_up: false,
        is_kya_pending: false,
        is_kya_recertification: false,
    }
}

const SET_SALES_PROSPECTS = (state, payload) => {
    state.salesProspects = payload
};

const SET_SALES_PROSPECTS_REACCREDITATION = (state, payload) =>{
    state.salesProspectsReAccreditation = payload
};
    
const SET_SP_NUMBER = (state, payload) =>  {
    state.spNumber = payload;
};

const UNSET_SP_NUMBER = (state) =>  {
    state.spNumber = '';
}

const SET_SCORE_CARD = (state, payload) =>  {
    state.salesProspectingForm.scorecard = payload;
    state.ReAccreditationDetails.scorecard = payload;
}

const SET_EXISTING_UPLOADED_FILES = (state, payload) =>  {
    state.existingUploadedFiles = payload;
}

const SET_EXISTING_UPLOADED_OWNERS = (state, payload) =>  {
    state.existingUploadedOwners = payload;
}

const SET_EXISTING_INITIAL_UPLOADED_FILES = (state, payload) =>  {
    state.existingInitialUploaded = payload;
}

const SET_EXISTING_UPLOADED_COMPLIANCE = (state, payload) =>  {
    state.existingUploadedCompliance = payload;
}

const SET_EXISTING_RISK_TOOL_DOCUMENTS = (state, payload) =>  {
    state.existingRiskAssessmentTool = payload;
}
const SET_EXISTING_RISK_TOOL_DETAILS = (state, payload) =>  {
    state.prpRiskAssessmentToolDetails.assessment_rating = payload.assessment_rating;
    state.prpRiskAssessmentToolDetails.risk_assessment_profile = payload.risk_assessment_profile;
}

const SET_RISK_TOOL_DEFAULT_DOCUMENTS = (state) =>  {
    state.prpRiskAssessmentToolDocuments =  {
        assessment_upload: {
            file: null,
        },
    }
}

const SET_EXISTING_UPLOADED_BRANCHES = (state, payload) => {
    state.existingUploadedBranches = payload;
}
const SET_EXISTING_PRIMARY_CONTACT_INFORMATION = (state, payload) => {
    state.existingUploadedPrimaryContactInformation = payload;
}

const SET_DEFAULT_INITIAL_UPLOADED_FILES = (state) => {
    state.initialUploaded = {
        upload_picture: null,
        upload_supp_docs: null,
        profile_picture: null,
        upload_loi: null,
        upload_amla: null, //adding upload for AML
        upload_kaizer: null, //adding upload for Kaizer

    }
}

const SET_DEFAULT_REACCREDITATION_INITIAL_UPLOADED_FILES = (state) => {
    state.ReAccreditationinitialUploaded = {
        upload_amla: null, //adding upload for AML
        upload_kaizer: null, //adding upload for Kaizer

    }
}
// const SET_CITIES = (state, payload) =>  {
//     state.cities = payload;
// }

const SET_COUNTRIES = (state, payload) =>  {
    state.countries = payload;
}

const SET_BRANCHES = (state, payload) => {
    state.branches = payload
}

const SET_OWNERS = (state, payload) => {
    state.owners = payload
}

const SET_DEFAULT_SP_FORM =  (state) =>  {
    state.salesProspectingForm =  {
        id: '',
        account_name: '',
        legal_entity_name: '',
        trade_name: '',
        bp_idno: null,
        scorecard: '',
        date_presentation: '',
        documents_presented: [],
        summary_presentation: '',
        accept_or_negotiate: '',
        scorecard_result: '',
        risk_profile:'', //storage for risk profiling
        amla_risk_rating: '', //storage for aml rating
        amla_risk_profile: '', //storage for aml profile
        upload: [
            {
                upload_picture: null,
                upload_loi: null,
                upload_supp_docs: null,
                upload_amla: null, //adding upload for AML
                upload_kaizer: null, //adding upload for Kaizer
            }
        ]
    }
}




const SET_RULE_EMAIL_EXISTING = (state) => {
    state.existingEmailRule =  [v => !!v || 'This field is required', v => /.+@.+/.test(v) || 'E-mail must be valid']
}

const SET_RULE_GENERIC_EXISTING = (state) => {
    state.existingGenericRule =  [v => !!v || 'This field is required']
}

const UNSET_RULE_GENERIC_EXISTING = (state) => {
    state.existingGenericRule =  []
}

const UNSET_RULE_EMAIL_EXISTING = (state) => {
    state.existingEmailRule =  []
}

const SET_RULE_FILE_EXISTING = (state) => {
    state.existingFileRUle = [v => !!v || 'File is required', v => (v && v.size > 0) || 'File is required']
}

const UNSET_RULE_FILE_EXISTING = (state) => {
    state.existingFileRUle =  []
}



const SET_RULE_GENERIC = (state) => {
    state.genericRule =  [v => !!v || 'This field is required']
}

const SET_RULE_CHECKBOX = (state) => {
    state.checkboxRule =  [v => v.length > 0 || 'You must select at least one']
}

const SET_RULE_SCORECARD = (state) => {
    state.scoreCardRule =  [v => !!v || 'The value must be greater than 0']
}

const SET_RULE_EMAIL = (state) => {
    state.emailRule =  [v => !!v || 'This field is required', v => /.+@.+/.test(v) || 'E-mail must be valid']
}

const SET_RULE_FILE = (state) => {
    state.fileRule = [v => !!v || 'File is required', v => (v && v.size > 0) || 'File is required']
}

const UNSET_RULE_GENERIC = (state) => {
    state.genericRule =  []
}

const UNSET_RULE_CHECKBOX = (state) => {
    state.checkboxRule =  []
}

const UNSET_RULE_SCORECARD = (state) => {
    state.scoreCardRule =  []
}

const UNSET_RULE_FILE = (state) => {
    state.fileRule =  []
}

const UNSET_RULE_EMAIL = (state) => {
    state.emailRule =  []
}

const SET_SELECTED_SP =  (state, payload) =>  {
    state.salesProspectingForm = payload
}

const SET_REACCREDITATION_DOCUMENTS_DEFAULT = (state) =>{
    state.ReAccreditationDocuments = {
        is_western_union: null,
        is_avp: null,
        is_nonex: null,
        single_proprietor: {
            dti: {
                name: '*DTI',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            business_permit: {
                name: '*Business Permit with Money Remittance/Remittance Agent',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            mother_of_contract: {
                name: 'Mother Contract',
                file: null
            },
            moa_avp: {
                name: 'AVP MOA',
                file: null
            },
            moa_nonex: {
                name: 'NONEX MOA',
                file: null
            },
            fs_itr:{
                name: '*FS/ITR',
                file: null
            },
            amla_questionnaire:{
                name: '*AMLA Questionnaire',
                file: null
            },
            amla_cor: {
                name: '*AMLA COR',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
        },
        cooperative_hybrid: {
            cda: {
                name: '*CDA',
                file: null,
                // date_issued: '',
                // date_expiry: '',
                // menuIssued: false,
                // menuExpiry: false
            },
            business_permit: {
                name: '*Business Permit with Money Remittance/Remittance Agent',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            mother_of_contract: {
                name: 'Mother Contract',
                file: null
            },
            bir_cor: {
                name: '*BIR COR/Certificate of Tax Exemption',
                file: null
            },

            fs_itr:{
                name: '*FS/ITR',
                file: null
            },
            moa_avp: {
                name: 'AVP MOA',
                file: null
            },
            moa_nonex: {
                name: 'NONEX MOA',
                file: null
            },
            amla_questionnaire:{
                name: '*AMLA Questionnaire',
                file: null
            },
            amla_cor: {
                name: '*AMLA COR',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
        },
        corporation: {
            sec: {
                name: '*SEC',
                file: null,
            },
            business_permit: {
                name: '*Business Permit with Money Remittance/Remittance Agent',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            gis: {
                name: '*GIS',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            mother_of_contract: {
                name: 'Mother Contract',
                file: null
            },
            secretary_certificate: {
                name: '*Secretary Certificate',
                file: null
            },
            bir_cor: {
                name: '*BIR COR',
                file: null
            },
            fs_itr:{
                name: '*FS/ITR',
                file: null
            },
            moa_avp: {
                name: 'AVP MOA',
                file: null
            },
            moa_nonex: {
                name: 'NONEX MOA',
                file: null
            },
            amla_questionnaire:{
                name: '*AMLA Questionnaire',
                file: null
            },
            amla_cor: {
                name: '*AMLA COR',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
        }
    }
}

const SET_DOCUMENTS_DEFAULT = (state) => {
    
    state.documents = {
        is_western_union: null,
        is_avp: null,
        is_nonex: null,
        single_proprietor: {
            dti: {
                name: 'DTI',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            business_permit: {
                name: 'Business Permit with Money Remittance/Remittance Agent',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            cmap: {
                name: 'CMAP',
                file: null
            },
            mother_of_contract: {
                name: 'Mother Contract',
                file: null
            },
            bir_cor: {
                name: 'BIR COR',
                file: null
            },
            supplier_reference: {
                name: 'Supplier Reference',
                file: null
            },
            fss_deed_of_undertaking:{
                name: 'FSS Licensing Form No. 01-003',
                file: null
            },

            fss_pdaf:{
                name: 'FSS Licensing Form No. 01-004 (PDAF)',
                file: null
            },
            fs_itr:{
                name: 'FS/ITR',
                file: null
            },
            // moa: {
            //     name:'MOA',
            //     file: null
            // },
            moa_avp: {
                name: 'AVP MOA',
                file: null
            },
            moa_nonex: {
                name: 'NONEX MOA',
                file: null
            },
            amla_questionnaire:{
                name: '*AMLA Questionnaire',
                file: null
            },
            amla_cor: {
                name: '*AMLA COR',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
        },
        cooperative_hybrid: {
            cda: {
                name: 'CDA',
                file: null,
                // date_issued: '',
                // date_expiry: '',
                // menuIssued: false,
                // menuExpiry: false
            },
            business_permit: {
                name: 'Business Permit with Money Remittance/Remittance Agent',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            cmap: {
                name: 'CMAP',
                file: null
            },
            mother_of_contract: {
                name: 'Mother Contract',
                file: null
            },
            secretary_certificate: {
                name: 'Secretary Certificate',
                file: null
            },
            bir_cor: {
                name: 'BIR COR/Certificate of Tax Exemption',
                file: null
            },
            supplier_reference: {
                name: 'Supplier Reference',
                file: null
            },
            org_chart: {
                name: 'Organizational Chart',
                file: null
            },
            board_resolution: {
                name: 'Board Resolution',
                file: null
            },
            fss_deed_of_undertaking:{
                name: 'FSS Licensing Form No. 01-003',
                file: null
            },

            fss_pdaf:{
                name: 'FSS Licensing Form No. 01-004 (PDAF)',
                file: null
            },

            fs_itr:{
                name: 'FS/ITR',
                file: null
            },
            moa_avp: {
                name: 'AVP MOA',
                file: null
            },
            moa_nonex: {
                name: 'NONEX MOA',
                file: null
            },
            amla_questionnaire:{
                name: '*AMLA Questionnaire',
                file: null
            },
            amla_cor: {
                name: '*AMLA COR',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
        },
        corporation: {
            sec: {
                name: 'SEC',
                file: null,
                // date_issued: '',
                // date_expiry: '',
                // menuIssued: false,
                // menuExpiry: false
            },
            business_permit: {
                name: 'Business Permit with Money Remittance/Remittance Agent',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            gis: {
                name: 'GIS',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            cmap: {
                name: 'CMAP',
                file: null
            },
            mother_of_contract: {
                name: 'Mother Contract',
                file: null
            },
            secretary_certificate: {
                name: 'Secretary Certificate',
                file: null
            },
            bir_cor: {
                name: 'BIR COR',
                file: null
            },
            supplier_reference: {
                name: 'Supplier Reference',
                file: null
            },
            org_chart: {
                name: 'Organizational Chart',
                file: null
            },
            board_resolution: {
                name: 'Board Resolution',
                file: null
            },
            fss_deed_of_undertaking:{
                name: 'FSS Licensing Form No. 01-003',
                file: null
            },

            fss_pdaf:{
                name: 'FSS Licensing Form No. 01-004 (PDAF)',
                file: null
            },

            fs_itr:{
                name: 'FS/ITR',
                file: null
            },
            moa_avp: {
                name: 'AVP MOA',
                file: null
            },
            moa_nonex: {
                name: 'NONEX MOA',
                file: null
            },
            amla_questionnaire:{
                name: '*AMLA Questionnaire',
                file: null
            },
            amla_cor: {
                name: '*AMLA COR',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
        }
    }
}

const SET_OWNERS_DEFAULT = (state) => {
    state.owners =   {
        first_name: '',
        middle_name: '',
        last_name: '',
        is_draft: '',
        suffix: '',
        percentage_ownership: '',
        address_line_1: '',
        address_line_2: '',
        country: 'Philippines',
        province:'',
        city: '',
        region: '',
        postal_code: '',
        landline: '',
        cellphone: '',
        email_address: '',
        valid_id: {
            file: null,
            // date_issued: '',
            // date_expiry: '',
            // menuIssued: false,
            // menuExpiry: false
        },
        court_clearance: {
            file: null,
            // date_issued: '',
            // date_expiry: '',
            // menuIssued: false,
            // menuExpiry: false
        },
        nbi: {
            file: null,
            // date_issued: '',
            // date_expiry: '',
            // menuIssued: false,
            // menuExpiry: false
        },
        amla: {
            file: null,
            date_issued: '',
            date_expiry: '',
            menuIssued: false,
            menuExpiry: false
        },
        picture_2x2: {
            file: null
        },
        is_same_primary: null,
        no_of_members: null
    }
}

const SET_DEFAULT_SP_FORM_INITIAL_UPLOAD = (state) => {
    state.existingInitialUploaded = {
        upload_picture: null,
        upload_supp_docs: null,
        profile_picture: null,
        upload_loi: null
    }
}

const SET_BRANCHES_DEFAULT = (state) => {
    state.branches =   {
        branch_name: '',
        is_draft: '',
        location_name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        region: '',
        country: 'Philippines',
        province:'',
        barangay: '',
        postal_code: '',
        email_address: '',
        landline: '',
        cellphone: '',
        handler_name: '',
        handler_email: '',
        tid: '',
        ftid: '',
        apz: '',
        api: '',
        fla_user_access:'',
        location_code: null,
        class_code_branch: '',
        justification:{
            file: null,
        },
        coordinates: {
            lat: '',
            lng: ''
        },
        business_permit: {
            file: null,
            date_issued: '',
            date_expiry: '',
            menuIssued: false,
            menuExpiry: false 
        },
        operating_hours: [
            {day:'MON', timeStart: '', timeEnd: '', isClosed: false}, 
            {day:'TUE', timeStart: '', timeEnd: '', isClosed: false}, 
            {day:'WED', timeStart: '', timeEnd: '', isClosed: false}, 
            {day:'THU', timeStart: '', timeEnd: '', isClosed: false}, 
            {day:'FRI', timeStart: '', timeEnd: '', isClosed: false},
            {day:'SAT', timeStart: '', timeEnd: '', isClosed: false},
            {day:'SUN', timeStart: '', timeEnd: '', isClosed: false},
        ],
        fla: [
            {
                first_name: '',
                middle_name: '',
                last_name: '',
                suffix: '',
                wupos_op_id: '',
                usp_op_id: '',
                fla_status:'',
                agent_code:'',
                amla: {
                    file: null,
                    date_issued: '',
                    date_expiry: '',
                    menuIssued: false,
                    menuExpiry: false
                },
                nbi: {
                    file: null,
                },
                // valid_id: {
                //     file: null,
                // },
                email_address: '',
                citizenship: 'Filipino',
                date_of_seminar: '',
                tin_no: '',
                tel_no: '',
                fax_no: '',
                is_wupos: null,
                is_usp: null,
                menuExpiry: false
            }
        ],
        is_same_primary: null,
        mailing_address_line_1: '',
        mailing_address_line_2: '',
        mailing_country: 'Philippines',
        mailing_city: '',
        mailing_province: '',
        mailing_postal_code: '',
        mailing_landline: '',
        mailing_cellphone: '',
        mailing_email_address: ''
    }
}

const SET_COMMENT = (state, payload) => {
    state.comment = payload
}

const SET_INITIALCOMMENT_DEFAULT = (state) => {
    state.comment = ''
}

const SET_VIEW_COMMENTS = (state, payload) => {
    state.setComments = payload
}
const SET_APPROVE_ONBOARDING_COMMENTS = (state, payload) => {
    state.approveOnboardingComments = payload
}
const SET_DISAPPROVE_ONBOARDING_COMMENTS = (state, payload) => {
    state.disapproveOnboardingComments = payload
}
const SET_APPROVE_VALIDATION_COMMENTS = (state, payload) => {
    state.approveValidationComments = payload
}
const SET_DISAPPROVE_VALIDATION_COMMENTS = (state, payload) => {
    state.disapproveValidationComments = payload
}
const SET_DISAPPROVE_COMMENTS = (state, payload) => {
    state.disapproveComments = payload
}

const SET_EXISTING_BSP = (state, payload) => {
    state.existingBSP = payload;
}

const SET_EXISTING_BSP_DEFAULT = (state) => {
    state.existingBSP = [];
}

const SET_DEFAULT_SP_FORM_EXISTING =  (state) =>  {
    state.salesProspectingForm =  {
        id: '',
        account_name: '',
        legal_entity_name: '',
        trade_name: '',
        date_presentation: '',
        documents_presented: [],
        summary_presentation: '',
        accept_or_negotiate: '',
        upload: [
            {
                upload_picture: null,
                upload_loi: null,
                upload_supp_docs: null
            }
        ]
    }
}

const SET_BSP_DEFAULT = (state) => {
    state.bsp = {
        bsp_license: {
            file: null,
            date_issued: '',
            date_expiry: '',
            menuIssued: false,
            menuExpiry: false
        }
    }
}

const SET_EXISTINGFILES_DEFAULT = (state) => {
    state.existingUploadedFiles = []
}

const SET_RULE_BSP = (state) => {
    state.bspRule =  [v => !!v || 'This field is required']
}

const UNSET_RULE_BSP = (state) => {
    state.bspRule =  []
}

const SET_SINGLE_UPLOADED = (state, payload) => {
    state.single_proprietor_upload =  payload
}

const SET_BOD_DEFAULT = (state) => {
    state.bod = {
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        no_of_shares: '',
        position: '',
        is_familial: 0,
        address_line_1: '',
        address_line_2: '',
        country: 'Philippines',
        city: '',
        province: '',
        region: '',
        postal_code: '',
        date_of_birth: '',
        is_draft: '',
        dob: {
            menuIssued: false,
            menuExpiry: false
        },
        valid_id: {
            file: null,
            // date_issued: '',
            // date_expiry: '',
            // menuIssued: false,
            // menuExpiry: false
        },
        amla_certificate: {
            file: null,
            // date_issued: '',
            // date_expiry: '',
            // menuIssued: false,
            // menuExpiry: false
        },
        nbi_clearance: {
            file: null,
            // date_issued: '',
            // date_expiry: '',
            // menuIssued: false,
            // menuExpiry: false
        },
    }
}
const SET_REACCREDITATION_BOD_DEFAULT = (state) => {
    state.ReAccreditationBod = {
        valid_id: {
            file: null,
        },
        amla_certificate: {
            file: null,
        },
        nbi_clearance: {
            file: null,
        },
    }
}
const SET_REACCREDITATION_BRANCH_DEFAULT = (state) => {
    state.ReAccreditationfla = {
        valid_id: {
            file: null,
        },
    }
}

const SET_REACCREDITATION_COMPLIANCE_DEFAULT = (state) => {
    state.ReAccreditationComplianceOfficer = {
        amla_certificate: {
            file: null,
        },
        valid_id: {
            file: null,
        },
    }
}

const SET_REACCREDITATION_PRIMARY_CONTACT_INFORMATION_DEFAULT = (state) => {
    state.ReAccreditationPrimaryContactInfo = {
        amla_certificate: {
            file: null,
        },
        valid_id: {
            file: null,
        },
    }
}

const SET_EXISTING_BOD = (state, payload) => {
    state.existingUploadedBod = payload;
}

const SET_EXISTING_BOD_DEFAULT = (state) => {
    state.existingUploadedBod = [];
}

const SET_ZIP = (state, payload) => {
    state.zip = payload
}

const SET_PRIMARYBUSINESSADD_ADDRESS = (state, payload) => {
    state.primaryBusinessAddress.city= payload.brpc_id_name,
    state.primaryBusinessAddress.region= payload.br_id_name,
    state.primaryBusinessAddress.country= 'Philippines',
    state.primaryBusinessAddress.province= payload.brp_id_name,
    state.primaryBusinessAddress.postal_code= payload.zipcode
}

const SET_MAILING_PRIMARYBUSINESSADD_ADDRESS = (state, payload) => {
    state.primaryBusinessAddress.mailing_city= payload.brpc_id_name,
    state.primaryBusinessAddress.mailing_region= payload.br_id_name,
    state.primaryBusinessAddress.mailing_country= 'Philippines',
    state.primaryBusinessAddress.mailing_province= payload.brp_id_name,
    state.primaryBusinessAddress.mailing_postal_code= payload.zipcode
}

const SET_CONTACTINFO_ADDRESS = (state, payload) => {
    
    state.primaryContactInfo.city= payload.brpc_id_name,
    state.primaryContactInfo.region= payload.br_id_name,
    state.primaryContactInfo.country= 'Philippines',
    state.primaryContactInfo.province= payload.brp_id_name,
    state.primaryContactInfo.postal_code= payload.zipcode

}

const SET_COMPLIANCE_ADDRESS = (state, payload) => {
   
    state.complianceOfficer.city= payload.brpc_id_name,
    state.complianceOfficer.region= payload.br_id_name,
    state.complianceOfficer.country= 'Philippines',
    state.complianceOfficer.province= payload.brp_id_name,
    state.complianceOfficer.postal_code= payload.zipcode
}

const SET_BRANCH_ADDRESS = (state, payload) => {

    state.branches.city = payload.brpc_id_name,
    state.branches.region = payload.br_id_name,
    state.branches.country = 'Philippines',
    state.branches.province = payload.brp_id_name,
    state.branches.postal_code = payload.zipcode

}

const SET_EXISTING_BRANCH_ADDRESS = (state, {finalPayload}) => {
    state.existingUploadedBranches[finalPayload.index].city = finalPayload.payload.brpc_id_name,
    state.existingUploadedBranches[finalPayload.index].region = finalPayload.payload.br_id_name,
    state.existingUploadedBranches[finalPayload.index].country = 'Philippines',
    state.existingUploadedBranches[finalPayload.index].province = finalPayload.payload.brp_id_name,
    state.existingUploadedBranches[finalPayload.index].postal_code = finalPayload.payload.zipcode

}

const SET_OWNER_ADDRESS = (state, payload) => {
    
    state.owners.country= 'Philippines',
    state.owners.province= payload.brp_id_name,
    state.owners.city= payload.brpc_id_name,
    state.owners.region= payload.br_id_name,
    state.owners.postal_code= payload.zipcode
}

const SET_EXISTING_OWNER_ADDRESS = (state, {finalPayload}) => {
    state.existingUploadedOwners[finalPayload.index].city = finalPayload.payload.brpc_id_name,
    state.existingUploadedOwners[finalPayload.index].region = finalPayload.payload.br_id_name,
    state.existingUploadedOwners[finalPayload.index].country = 'Philippines',
    state.existingUploadedOwners[finalPayload.index].province = finalPayload.payload.brp_id_name,
    state.existingUploadedOwners[finalPayload.index].postal_code = finalPayload.payload.zipcode

}

const SET_BOD_ADDRESS = (state, payload) => {
    
    state.bod.country= 'Philippines',
    state.bod.city= payload.brpc_id_name,
    state.bod.province= payload.brp_id_name,
    state.bod.region= payload.br_id_name,
    state.bod.postal_code= payload.zipcode

}

const SET_EXISTING_BOD_ADDRESS = (state, {finalPayload}) => {
    state.existingUploadedBod[finalPayload.index].city = finalPayload.payload.brpc_id_name,
    state.existingUploadedBod[finalPayload.index].region = finalPayload.payload.br_id_name,
    state.existingUploadedBod[finalPayload.index].country = 'Philippines',
    state.existingUploadedBod[finalPayload.index].province = finalPayload.payload.brp_id_name,
    state.existingUploadedBod[finalPayload.index].postal_code = finalPayload.payload.zipcode

}

const SET_BRANCH_COORDINATES = (state, {payload}) => {
    // console.log(payload)
    state.branches.coordinates.lat = payload.lat
    state.branches.coordinates.lng = payload.lng
}

const SET_EXISTING_BRANCH_COORDINATES = (state, {payload}) => {
    // console.log(payload)
    state.existingUploadedBranches[payload.index].coordinates.lat = payload.lat
    state.existingUploadedBranches[payload.index].coordinates.lng = payload.lng
}

const SET_RENEWABLE_FILES = (state, payload) =>  {
    state.renewableUploadedFiles = payload;
}

const SET_RENEWABLE_FILES_DEFAULT = (state) =>  {
    state.renewableUploadedFiles =  []
}
const SET_RENEWABLE_BRANCH_PERMIT = (state, payload) =>  {
    state.renewableUploadedBranch = payload;
}

const SET_RENEWABLE_BRANCH_PERMIT_DEFAULT = (state) =>  {
    state.renewableUploadedBranch =  []
}
const SET_RENEWABLE_BRANCH_AMLA = (state, payload) =>  {
    state.renewableUploadedBranchAmla = payload;
}

const SET_RENEWABLE_BRANCH_AMLA_DEFAULT = (state) =>  {
    state.renewableUploadedBranchAmla =  []
}

const SET_BRANCHDIALOG = (state, payload) => {
    state.branchDialog = payload
}

const SET_ID_BRANCH_PERMIT = (state, payload) => {
    state.branch_business_permit.id = payload
}

const SET_ID_AMLA = (state, payload) => {
    state.fla_amla.id = payload
}

const SET_AMLADIALOG = (state, payload) => {
    state.amlaDialog = payload
}

const SET_RENEWABLE_OWNER_AMLA = (state, payload) =>  {
    state.renewableUploadedOwnerAmla = payload;
}

const SET_RENEWABLE_OWNER_AMLA_DEFAULT = (state) =>  {
    state.renewableUploadedOwnerAmla = [];
}

const SET_OWNER_AMLADIALOG = (state, payload) => {
    state.ownerAmlaDialog = payload
}

const SET_ID_OWNER_AMLA = (state, payload) => {
    state.owner_amla.id = payload
}

const SET_FINAL_COMMENT = (state, payload) => {
    state.final_comment = payload
}


const SET_ONBOARDING_APPROVED_COMMENT = (state, payload) => {
    state.onBoardingCommentsApproved = payload
}

const SET_ONBOARDING_DISAPPROVED_COMMENT = (state, payload) => {
    state.onBoardingCommentsDisapproved = payload
}

const SET_VALIDATION_APPROVED_COMMENT = (state, payload) => {
    state.validationCommentsApproved= payload
}

const SET_VALIDATION_DISAPPROVED_COMMENT = (state, payload) => {
    state.validationCommentsDisapproved = payload
}

const SET_VIEW_FINAL_COMMENTS = (state, payload) => {
    state.setFinalComments = payload
}

const SET_RETURNED_EVA_COMMENTS = (state, payload) => {
    state.returnedEvaComments = payload
}

const SET_COMMENT_DEFAULT = (state) => {
    state.setComments = []
}

const SET_FINAL_COMMENT_DEFAULT = (state) => {
    state.setFinalComments = []
}

const SET_DOCUMENTS_MAINTENANCE = (state, payload) => {
    state.documentMaintenance = payload
}

const SET_DOCUMENTS_MAINTENANCE_DEFAULT = (state) => {
    state.documentMaintenance = []
}

const SET_DOCS_MAINTENANCE_DEFAULT = (state) => {
    state.documents_maintenance = {
        document_title: '',
        is_default: true,
        primary: '',
        secondary: '',
        primary_date: {
            menuIssued: false,
            menuExpiry: false
        },
        secondary_date: {
            menuIssued: false,
            menuExpiry: false
        },
    }
}

const SET_DOCS_MAINTENANCE_RESET = (state) => {
    state.documents_maintenance.is_default = true,
    state.documents_maintenance.primary= null,
    state.documents_maintenance.secondary= null,
    state.documents_maintenance.primary_date = {
        menuIssued: false,
        menuExpiry: false
    },
    state.documents_maintenance.secondary_date = {
        menuIssued: false,
        menuExpiry: false
    }
}

const SET_WITNESS_DEFAULT = (state) => {
    state.witness = {
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        position: '',
        valid_id: null
    }

}

const SET_EXISTING_WITNESS = (state, payload) => {
    state.existingWitnessUploaded = payload;
}

const SET_EXISTING_WITNESS_DEFAULT = (state) => {
    state.existingWitnessUploaded = [];
}

const SET_EXISTING_BRANCH_DEFAULT = (state) => {
    state.existingUploadedBranches = []
}

const SET_SIGNATORY_DEFAULT = (state) => {
    state.signatory = {
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        position: '',
        id_expiry:'',
        id_type:'',
        id_number:'',
        valid_id: null
    }

}
const SET_EXISTING_SIGNATORY = (state, payload) => {
    state.existingSignatoryUploaded = payload;
}

const SET_EXISTING_SIGNATORY_DEFAULT = (state) => {
    state.existingSignatoryUploaded = [];
}

const SET_MOA_DEFAULT = (state) => {
    state.moa = {
        moa_type: '',
        day_execution:'',
        month_execution: '',
        salutation: '',
        year_execution: '',
        address_execution: '',
        is_send: null,
        is_op_req_only: null,
        appendices: '',
        service_fee_words: '',
        service_fee_figures: '',
        period_term_months: '',
        period_term_years: '',
        sub_representative: {
            first_name: '',
            middle_name:'',
            last_name:'',
            suffix: '',
            position: '',
            id_expiry:'',
            id_type:'',
            id_number:'',
            menuIssued: false,
            menuExpiry: false
        },
        app_form: {
            account_name: '',
            address_line_1: '',
            address_line_2: '',
            city: '',
            region: '',
            country: '',
            postal_code: '',
            email_address: '',
            landline: '',
            cellphone: '',
            province: ''
        },
        cover_date_header: '',
        cover_dear: '',
        midnight_date: '',
        is_dengue: false,
        is_payapa: false,
        is_malayan: false,
        is_visa_selling: false,
        is_visa_cashin: false,
        is_gcash_in: false,
        is_gcash_out: false,
        is_paymaya: false,
        is_coins: false,
        is_dragonpay: false,
        is_unionbank: false,
        is_airline: false,
        is_eload: false,
        is_bills_pay: false,
        is_others: false,
        other_products_array: [],
        other_products: '',
        to_email: [],
        cc_email: [],
        bcc_email: [],
        pet_name: 'Madeline Elba B. Gacutan',
        pet_id_type: 'UMID',
        pet_id_number: '0111-6405981-5',
        pet_id_expiry: 'N/A',
        pet_menuIssued: false,
        pet_menuExpiry: false,
        cover_name: '',
        cover_position: '',
        cover_department: '',
        cover_number: '',
        signatory: {
            first_name: '',
            middle_name: '',
            last_name: '',
            suffix: '',
            position: '',
            id_expiry:'',
            id_type:'',
            id_number:'',
        },
    }
}

const SET_MOA = (state, payload) => {
    state.moa = payload
}
const SET_PROVINCE = (state, payload) => {
    state.province = payload
}

const SET_BUSINESSADD_REGION = (state, payload) => {
    state.primaryBusinessAddress.region = payload
}

const SET_BUSINESSADD_CITY = (state, payload) => {
    state.businessAddCity = payload
}
const SET_BUSINESSADD_ZIP = (state, payload) => {
    state.businessAddZip = payload
}

const SET_MAILINGBUSINESSADD_REGION = (state, payload) => {
    state.primaryBusinessAddress.mailing_region = payload
}

const SET_MAILINGBUSINESSADD_CITY = (state, payload) => {
    state.mailingBusinessAddCity = payload
}

const SET_MAILINGBUSINESSADD_ZIP = (state, payload) => {
    state.mailingBusinessAddZip = payload
}

const SET_PRIMARYCONTACT_REGION = (state, payload) => {
    state.primaryContactInfo.region = payload
}

const SET_PRIMARYCONTACT_CITY = (state, payload) => {
    state.primaryContactCity = payload
}

const SET_PRIMARYCONTACT_ZIP = (state, payload) => {
    state.primaryContactZip = payload
}

const SET_BUSINESSADD_CITY_DEFAULT = (state) => {
    state.businessAddCity = []
}

const SET_BUSINESSADD_ZIP_DEFAULT = (state) => {
    state.businessAddZip = []
}

const SET_MAILINGBUSINESSADD_ZIP_DEFAULT = (state) => {
    state.mailingBusinessAddZip = []
}

const SET_MAILINGBUSINESSADD_CITY_DEFAULT = (state) => {
    state.mailingBusinessAddCity = []
}

const SET_PRIMARYCONTACT_CITY_DEFAULT = (state) => {
    state.primaryContactCity = []
}

const SET_PRIMARYCONTACT_ZIP_DEFAULT = (state) => {
    state.primaryContactZip = []
}

const SET_COMPLIANCE_REGION = (state, payload) => {
    state.complianceOfficer.region = payload
}

const SET_COMPLIANCE_CITY = (state, payload) => {
    state.complianceCity = payload
}

const SET_COMPLIANCE_CITY_DEFAULT = (state) => {
    state.complianceCity = []
}

const SET_COMPLIANCE_ZIP = (state, payload) => {
    state.complianceZip = payload
}

const SET_COMPLIANCE_ZIP_DEFAULT = (state,) => {
    state.complianceZip = []
}

const SET_BRANCHES_REGION = (state, payload) => {
    state.branches.region = payload
}

const SET_BRANCHES_CITY = (state, payload) => {
    state.branchesCity = payload
}

const SET_BRANCHES_CITY_DEFAULT = (state) => {
    state.branchesCity = []
}

const SET_BRANCHES_ZIP = (state, payload) => {
    state.branchesZip = payload
}

const SET_BRANCHES_ZIP_DEFAULT = (state) => {
    state.branchesZip = []
}

const SET_EXISTINGBRANCHES_REGION = (state, {finalPayload}) => {
    console.log(finalPayload.index)
    state.existingUploadedBranches[finalPayload.index].region = finalPayload.payload
}

const SET_EXISTINGBRANCHES_REGION_MAILING = (state, {finalPayload}) => {
    console.log(finalPayload.index)
    state.existingUploadedBranches[finalPayload.index].mailing_region = finalPayload.payload
}

const SET_EXISTINGBRANCHES_CITY = (state, {payload}) => {
    state.existingBranchesCity[payload.index] = newCity
    var newCity = state.existingBranchesCity.splice(payload.index, 1, payload.payload)
}

const SET_EXISTINGBRANCHES_CITY_MAILING = (state, {payload}) => {
    state.existingBranchesCityMailing[payload.index] = newCity
    var newCity = state.existingBranchesCityMailing.splice(payload.index, 1, payload.payload)
}

const SET_EXISTINGBRANCHES_ZIP = (state, {finalPayload}) => {
    console.log(finalPayload.index)
    state.existingBranchesZip[finalPayload.index] = newZip
    var newZip = state.existingBranchesZip.splice(finalPayload.index, 1, finalPayload.payload)
}

const SET_EXISTINGBRANCHES_ZIP_MAILING = (state, {finalPayload}) => {
    console.log(finalPayload.index)
    state.existingBranchesZipMailing[finalPayload.index] = newZip
    var newZip = state.existingBranchesZipMailing.splice(finalPayload.index, 1, finalPayload.payload)
}

const SET_EXISTINGBRANCHES_CITY_DEFAULT = (state) => {
    state.existingBranchesCity = []
}

const SET_EXISTINGBRANCHES_ZIP_DEFAULT = (state) => {
    state.existingBranchesZip = []
}

const SET_OWNERS_REGION = (state, payload) => {
    state.owners.region = payload
}

const SET_OWNERS_CITY = (state, payload) => {
    state.ownersCity = payload
}

const SET_OWNERS_ZIP = (state, payload) => {
    state.ownersZip = payload
}

const SET_OWNERS_CITY_DEFAULT = (state) => {
    state.ownersCity = []
}

const SET_OWNERS_ZIP_DEFAULT = (state) => {
    state.ownersZip = []
}

const SET_EXISTINGOWNERS_REGION = (state, {finalPayload}) => {
    state.existingUploadedOwners[finalPayload.index].region = finalPayload.payload
}

const SET_EXISTINGOWNERS_CITY = (state, {payload}) => {
    state.existingOwnersCity[payload.index] = newCity
    var newCity = state.existingOwnersCity.splice(payload.index, 1, payload.payload)
}

const SET_EXISTINGOWNERS_ZIP = (state, {finalPayload}) => {
    console.log(finalPayload.index)
    state.existingOwnersZip[finalPayload.index] = newZip
    var newZip = state.existingOwnersZip.splice(finalPayload.index, 1, finalPayload.payload)
}

const SET_EXISTINGOWNERS_CITY_DEFAULT = (state) => {
    state.existingOwnersCity = []
}
const SET_EXISTINGOWNERS_ZIP_DEFAULT = (state) => {
    state.existingOwnersZip = []
}

const SET_BOD_REGION = (state, payload) => {
    state.bod.region = payload
}

const SET_BOD_CITY = (state, payload) => {
    state.bodCity = payload
}

const SET_BOD_ZIP = (state, payload) => {
    state.bodZip = payload
}

const SET_BOD_CITY_DEFAULT = (state) => {
    state.bodCity = []
}

const SET_BOD_ZIP_DEFAULT = (state) => {
    state.bodZip = []
}

const SET_EXISTINGBOD_REGION = (state, {finalPayload}) => {
    state.existingUploadedBod[finalPayload.index].region = finalPayload.payload
}

const SET_EXISTINGBOD_CITY = (state, {payload}) => {
    state.existingBodCity[payload.index] = newCity
    var newCity = state.existingBodCity.splice(payload.index, 1, payload.payload)
}

const SET_EXISTINGBOD_ZIP = (state, {finalPayload}) => {
    state.existingBodZip[finalPayload.index] = newZip
    var newZip = state.existingBodZip.splice(finalPayload.index, 1, finalPayload.payload)
}

const SET_EXISTINGBOD_CITY_DEFAULT = (state) => {
    state.existingBodCity = []
}

const SET_EXISTINGBOD_ZIP_DEFAULT = (state) => {
    state.existingBodZip = []
}

const SET_COMPLIANCE_RETURNED_COMMENTS = (state, payload) => {
    state.compliance_returned_comments.push(payload)
}

const SET_ENCODER_RETURNED_COMMENTS = (state, payload) => {
    state.encoder_returned_comments.push(payload)
}

const SET_COMPLIANCE_RETURNED_COMMENTS_DEFAULT = (state) => {
    state.compliance_returned_comments = []
}
const SET_ENCODER_RETURNED_COMMENTS_DEFAULT = (state) => {
    state.encoder_returned_comments = []
}

const SET_COMPLIANCE_APPROVED_COMMENTS = (state, payload) => {
    state.compliance_approved_comments.push(payload)
}

const SET_COMPLIANCE_APPROVED_COMMENTS_DEFAULT = (state) => {
    state.compliance_approved_comments = []
}

const SET_LOGS_DIALOG = (state, payload) => {
    state.logsDialog = payload
}

const SET_SAS_DETAILS = (state, payload) => {
    state.sasDetails = payload
};

const SET_CLUSTER_DETAILS = (state, payload) => {
    state.clusterDetails = payload
};

const SET_SAM_USERS = (state, payload) => {
    state.users = payload
};

const SET_RADIO1 = (state, payload) => {
    state.primaryDetailsRadio1 = payload
};

const SET_RADIO2 = (state, payload) => {
    state.primaryDetailsRadio2 = payload
};

const SET_RADIO_DEFAULT = (state) => {
    state.primaryDetailsRadio1 = ''
    state.primaryDetailsRadio2 = ''
};

const SET_WESTERN_MOA = (state, payload) => {
    state.documents.is_western_union = payload
};

const SET_AVP_MOA = (state, payload) => {
    state.documents.is_avp = payload
};

const SET_NONEX_MOA = (state, payload) => {
    state.documents.is_nonex = payload
};

const SET_MOA_CHECKBOX_DEFAULT = (state) => {
    state.documents.is_western_union = false
    state.documents.is_avp = false
    state.documents.is_nonex = false
};
const SET_AVP_DETAILS = (state, payload) =>{
    state.avp_details = payload
}
const SET_AVP_DETAIL_DEFAULT = (state) => {
    state.avp_details = ''
}
const SET_BRANCHES_MAILING_REGION = (state, payload) => {
    state.branches.mailing_region = payload
}
const SET_BRANCHES_MAILING_CITY = (state, payload) => {
    state.branchMailingCity = payload
}
const SET_BRANCHES_MAILING_ZIP = (state, payload) => {
    state.branchMailingZip = payload
}

const SET_MOTHERENTITY_DETAILS = (state, payload) => {
    state.mother_entity_details = payload
}
const SET_MOTHERENTITY_DETAILS_DEFAULT = (state) => {
    state.mother_entity_details= {
        mother_entity_id: null,
        is_mother_entity: false,
        sub_entities: [],
    }
}

export default {
    SET_SALES_PROSPECTS,
    SET_SP_NUMBER,
    UNSET_SP_NUMBER,
    SET_SCORE_CARD,
    SET_DEFAULT_SP_FORM,
    SET_EXISTING_UPLOADED_FILES,
    SET_COUNTRIES,
    SET_BRANCHES,
    SET_DOCUMENTS_DEFAULT,
    SET_BRANCHES_DEFAULT,
    SET_OWNERS_DEFAULT,
    SET_OWNERS,
    SET_COMPLIANCE_OFFICER,
    SET_PRIMARY_BUSINESS_ADDRESS,
    SET_PRIMARY_CONTACT_INFO,
    SET_OTHER_PRIMARY_DETAILS,
    SET_PRIMARY_DETAILS,
    SET_EXISTING_UPLOADED_OWNERS,
    SET_EXISTING_INITIAL_UPLOADED_FILES,
    SET_DEFAULT_INITIAL_UPLOADED_FILES,
    SET_SELECTED_SP,
    SET_RULE_GENERIC,
    SET_RULE_CHECKBOX,
    SET_RULE_SCORECARD,
    SET_DEFAULT_SP_FORM_INITIAL_UPLOAD,
    UNSET_RULE_GENERIC,
    UNSET_RULE_CHECKBOX,
    UNSET_RULE_SCORECARD,
    SET_EXISTING_UPLOADED_BRANCHES,
    SET_RULE_EMAIL,
    SET_RULE_FILE,
    UNSET_RULE_FILE,
    UNSET_RULE_EMAIL,
    SET_COMMENT,
    SET_VIEW_COMMENTS,
    SET_EXISTING_BSP,
    SET_DEFAULT_SP_FORM_EXISTING,
    SET_BSP_DEFAULT,
    SET_EXISTINGFILES_DEFAULT,
    SET_RULE_BSP,
    UNSET_RULE_BSP,
    SET_PRIMARY_BUSINESS_ADDRESS_DEFAULT,
    SET_PRIMARY_CONTACT_INFO_DEFAULT,
    SET_COMPLIANCE_OFFICER_DEFAULT,
    SET_OTHER_PRIMARY_DETAILS_DEFAULT,
    SET_RULE_EMAIL_EXISTING,
    SET_RULE_GENERIC_EXISTING,
    UNSET_RULE_GENERIC_EXISTING,
    UNSET_RULE_EMAIL_EXISTING,
    SET_RULE_FILE_EXISTING,
    UNSET_RULE_FILE_EXISTING,
    SET_SINGLE_UPLOADED,
    SET_BOD_DEFAULT,
    SET_EXISTING_BOD,
    SET_PRIMARYBUSINESSADD_ADDRESS,
    SET_ZIP,
    SET_CONTACTINFO_ADDRESS,
    SET_COMPLIANCE_ADDRESS,
    SET_BRANCH_ADDRESS,
    SET_OWNER_ADDRESS,
    SET_BOD_ADDRESS,
    SET_BRANCH_COORDINATES,
    SET_EXISTING_BRANCH_COORDINATES,
    SET_EXISTING_BRANCH_ADDRESS,
    SET_EXISTING_OWNER_ADDRESS,
    SET_EXISTING_BOD_ADDRESS,
    SET_RENEWABLE_FILES,
    SET_RENEWABLE_FILES_DEFAULT,
    SET_RENEWABLE_BRANCH_PERMIT,
    SET_RENEWABLE_BRANCH_PERMIT_DEFAULT,
    SET_RENEWABLE_BRANCH_AMLA,
    SET_RENEWABLE_BRANCH_AMLA_DEFAULT,
    SET_BRANCHDIALOG,
    SET_ID_BRANCH_PERMIT,
    SET_AMLADIALOG,
    SET_ID_AMLA,
    SET_RENEWABLE_OWNER_AMLA,
    SET_RENEWABLE_OWNER_AMLA_DEFAULT,
    SET_OWNER_AMLADIALOG,
    SET_ID_OWNER_AMLA,
    SET_FINAL_COMMENT,
    SET_VIEW_FINAL_COMMENTS,
    SET_COMMENT_DEFAULT,
    SET_FINAL_COMMENT_DEFAULT,
    SET_DOCUMENTS_MAINTENANCE,
    SET_DOCUMENTS_MAINTENANCE_DEFAULT,
    SET_DOCS_MAINTENANCE_DEFAULT,
    SET_DOCS_MAINTENANCE_RESET,
    SET_WITNESS_DEFAULT,
    SET_EXISTING_WITNESS,
    SET_EXISTING_WITNESS_DEFAULT,
    SET_EXISTING_BRANCH_DEFAULT,
    SET_EXISTING_BSP_DEFAULT,
    SET_EXISTING_SIGNATORY,
    SET_EXISTING_SIGNATORY_DEFAULT,
    SET_SIGNATORY_DEFAULT,
    SET_MOA_DEFAULT,
    SET_EXISTING_BOD_DEFAULT,
    SET_MAILING_PRIMARYBUSINESSADD_ADDRESS,
    SET_PROVINCE,
    SET_BUSINESSADD_CITY,
    SET_BUSINESSADD_REGION,
    SET_BUSINESSADD_ZIP,
    SET_MAILINGBUSINESSADD_REGION,
    SET_MAILINGBUSINESSADD_CITY,
    SET_MAILINGBUSINESSADD_ZIP,
    SET_PRIMARYCONTACT_REGION,
    SET_PRIMARYCONTACT_CITY,
    SET_PRIMARYCONTACT_ZIP,
    SET_BUSINESSADD_CITY_DEFAULT,
    SET_MAILINGBUSINESSADD_ZIP_DEFAULT,
    SET_BUSINESSADD_ZIP_DEFAULT,
    SET_MAILINGBUSINESSADD_CITY_DEFAULT,
    SET_PRIMARYCONTACT_CITY_DEFAULT,
    SET_PRIMARYCONTACT_ZIP_DEFAULT,
    SET_COMPLIANCE_REGION,
    SET_COMPLIANCE_CITY,
    SET_COMPLIANCE_CITY_DEFAULT,
    SET_COMPLIANCE_ZIP,
    SET_COMPLIANCE_ZIP_DEFAULT,
    SET_BRANCHES_REGION,
    SET_BRANCHES_CITY,
    SET_BRANCHES_CITY_DEFAULT,
    SET_BRANCHES_ZIP,
    SET_BRANCHES_ZIP_DEFAULT,
    SET_EXISTINGBRANCHES_REGION,
    SET_EXISTINGBRANCHES_CITY,
    SET_EXISTINGBRANCHES_ZIP,
    SET_EXISTINGBRANCHES_CITY_DEFAULT,
    SET_EXISTINGBRANCHES_ZIP_DEFAULT,
    SET_OWNERS_REGION,
    SET_OWNERS_CITY,
    SET_OWNERS_ZIP,
    SET_OWNERS_CITY_DEFAULT,
    SET_OWNERS_ZIP_DEFAULT,
    SET_EXISTINGOWNERS_REGION,
    SET_EXISTINGOWNERS_CITY,
    SET_EXISTINGOWNERS_ZIP,
    SET_EXISTINGOWNERS_CITY_DEFAULT,
    SET_EXISTINGOWNERS_ZIP_DEFAULT,
    SET_BOD_REGION,
    SET_BOD_CITY,
    SET_BOD_ZIP,
    SET_BOD_CITY_DEFAULT,
    SET_BOD_ZIP_DEFAULT,
    SET_EXISTINGBOD_REGION,
    SET_EXISTINGBOD_CITY,
    SET_EXISTINGBOD_ZIP,
    SET_EXISTINGBOD_CITY_DEFAULT,
    SET_EXISTINGBOD_ZIP_DEFAULT,
    SET_MOA,
    SET_RETURNED_EVA_COMMENTS,
    SET_DISAPPROVE_COMMENTS,
    SET_COMPLIANCE_RETURNED_COMMENTS,
    SET_COMPLIANCE_RETURNED_COMMENTS_DEFAULT,
    SET_COMPLIANCE_APPROVED_COMMENTS,
    SET_COMPLIANCE_APPROVED_COMMENTS_DEFAULT,
    SET_LOGS_DIALOG,
    SET_INITIALCOMMENT_DEFAULT,
    SET_ENCODER_RETURNED_COMMENTS,
    SET_ENCODER_RETURNED_COMMENTS_DEFAULT,
    SET_SAS_DETAILS,
    SET_CLUSTER_DETAILS,
    SET_SAM_USERS,
    SET_RADIO1,
    SET_RADIO2,
    SET_RADIO_DEFAULT,
    SET_WESTERN_MOA,
    SET_AVP_MOA,
    SET_NONEX_MOA,
    SET_MOA_CHECKBOX_DEFAULT,
    SET_AVP_DETAILS,
    SET_AVP_DETAIL_DEFAULT,
    SET_BRANCHES_MAILING_REGION,
    SET_BRANCHES_MAILING_CITY,
    SET_BRANCHES_MAILING_ZIP,
    SET_EXISTINGBRANCHES_REGION_MAILING,
    SET_EXISTINGBRANCHES_CITY_MAILING,
    SET_EXISTINGBRANCHES_ZIP_MAILING,
    SET_MOTHERENTITY_DETAILS,
    SET_MOTHERENTITY_DETAILS_DEFAULT,
    SET_SALES_PROSPECTS_REACCREDITATION,
    SET_DEFAULT_REACCREDITATION_INITIAL_UPLOADED_FILES,
    SET_REACCREDITATION_DOCUMENTS_DEFAULT,
    SET_EXISTING_UPLOADED_COMPLIANCE,
    SET_REACCREDITATION_BOD_DEFAULT,
    SET_REACCREDITATION_BRANCH_DEFAULT,
    SET_REACCREDITATION_COMPLIANCE_DEFAULT,
    SET_REACCREDITATION_PRIMARY_CONTACT_INFORMATION_DEFAULT,
    SET_EXISTING_PRIMARY_CONTACT_INFORMATION,
    SET_EXISTING_RISK_TOOL_DOCUMENTS,
    SET_RISK_TOOL_DEFAULT_DOCUMENTS,
    SET_EXISTING_RISK_TOOL_DETAILS,
    SET_ONBOARDING_APPROVED_COMMENT,
    SET_ONBOARDING_DISAPPROVED_COMMENT,
    SET_VALIDATION_APPROVED_COMMENT,
    SET_VALIDATION_DISAPPROVED_COMMENT,
    SET_APPROVE_ONBOARDING_COMMENTS,
    SET_DISAPPROVE_ONBOARDING_COMMENTS,
    SET_APPROVE_VALIDATION_COMMENTS,
    SET_DISAPPROVE_VALIDATION_COMMENTS,
    
}
  