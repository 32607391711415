import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
// import router from '../../../router';
// import Vue from 'vue';

const salesProspectingRepository = RepositoryFactory.get('salesProspecting');

const doGetAllSalesProspecting = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAllSalesProspecting()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
                commit('SET_SALES_PROSPECTS', response.data.result)
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAllowedBsp = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAllAllowedBsp()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
                commit('SET_SALES_PROSPECTS', response.data.result)
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAllListSalesProspecting = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAllListSalesProspecting()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
                commit('SET_SALES_PROSPECTS', response.data.result)
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAllTerminationPending = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAllTerminationPending()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
                commit('SET_SALES_PROSPECTS', response.data.result)
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doCreateSPNumber  = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.createSPNumber(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
                commit('SET_SP_NUMBER', response.data.result)
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doSaveSalesProspecting  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.saveSalesProspecting({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('UNSET_SP_NUMBER')
				commit('SET_DEFAULT_SP_FORM')
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUpdateSalesProspecting  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updateSalesProspecting({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('UNSET_SP_NUMBER')
				commit('SET_DEFAULT_SP_FORM')
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doReAccreditationSaveSalesProspecting  = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.reAccreditationSaveSalesProspecting(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('UNSET_SP_NUMBER')
				commit('SET_DEFAULT_SP_FORM')
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});



const doUpdateExistingSalesProspecting  = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updateExistingSalesProspecting(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});


const doUpdateBusinessInformation  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updateBusinessInformation({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadFiles = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.uploadFiles({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUpdateOwner = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updateOwner({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUpdateBranch = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updateBranch({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadOwner = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.uploadOwner({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadBranch = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.uploadBranch({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetUploadedFiles = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getUploadedFiles(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetSalesProspecting = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getSalesProspecting(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doRetrieveUploaded =  ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.retrieveUploaded(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doApproveSPByEval = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.approveSPByEval(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Successfully approved SP! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDisapproveSPByPresident  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.disapproveSPByPresident({payload})
			.then((response) => {
				console.log(response)
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doApproveSPByPres = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.approveSPByPres(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Successfully approved SP! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doApproveSPByOnboarding = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.approveSPByOnboarding({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Successfully approved SP! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetCountries = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCountries()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_COUNTRIES',response.data.result);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

// const doGetCities = ({commit}) => new Promise((resolve,reject) => {
// 	commit('app/SET_IS_LOADING',true,{ root: true });
// 	setTimeout(() => {
// 		salesProspectingRepository.getCities()
// 			.then((response) => {
// 				commit('app/SET_IS_LOADING',false,{ root: true });
// 				commit('SET_CITIES',response.data.result);
// 				resolve(response);
// 			})
// 			.catch((err) => {
// 				commit('app/SET_IS_LOADING',false,{ root: true });
// 				reject(err);
// 			});
// 	}, 2000);
// });

const doInitialUpload  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.initialUpload({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				// commit('SET_CITIES',response.data.result);
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Uploading Successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});


const doCreateComment_Eva  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.createComment_Eva({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				// commit('SET_CITIES',response.data.result);
				resolve(response);
				// commit('app/SET_SNACK_BAR',{'status':true,'message': 'Uploading Successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doCreateComment_Ceo  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.createComment_Ceo({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				// commit('SET_CITIES',response.data.result);
				resolve(response);
				// commit('app/SET_SNACK_BAR',{'status':true,'message': 'Uploading Successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadBSP = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.uploadBSP({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGenerateMoa  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.generateMoa({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadBod = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.uploadBod({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUpdateComplianceDocuments = ({commit}, {payload}) => new Promise((resolve, reject) =>{
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updateComplianceDocuments({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
})

const doUpdatePrimaryContactInformationDocuments = ({commit}, {payload}) => new Promise((resolve, reject) =>{
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updatePrimaryContactInformationDocuments({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
})

const doUploadRiskTool = ({commit}, {payload}) => new Promise((resolve, reject) =>{
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.uploadRiskTool({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
})

const doUpdateBod = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updateBod({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doApproveSPByValidation = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.approveSPByValidation(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Successfully approved SP! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

// const doGetZip = (payload) => {
//     context.commit('app/SET_IS_LOADING', true , { root: true });
//     return new Promise((resolve, reject) => {
//         salesProspectingRepository.getZip(payload.token)
//             .then((response) => {
//                 context.commit('app/SET_IS_LOADING', false , { root: true });
//                 resolve(response);
//             }, error => {
//                 context.commit('app/SET_IS_LOADING', false , { root: true });
//                 reject(error);
//             });
//     });
// };

const doGetZip = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getZip(payload)
			.then((response) => {

				let zip = []

				Object.values(response.data.body).forEach(val => zip.push(val.name))
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_ZIP',zip);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAddressBusinessAdd = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAddress(payload.payload, payload.token)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_PRIMARYBUSINESSADD_ADDRESS',response.data.body[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetMailingAddressBusinessAdd = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAddress(payload.payload, payload.token)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_MAILING_PRIMARYBUSINESSADD_ADDRESS',response.data.body[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAddressContactInfo = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAddress(payload.payload, payload.token)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_CONTACTINFO_ADDRESS',response.data.body[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAddressCompliance = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAddress(payload.payload, payload.token)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_COMPLIANCE_ADDRESS',response.data.body[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAddressBranch = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAddress(payload.payload, payload.token)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BRANCH_ADDRESS',response.data.body[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAddressExistingBranch = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAddress(payload.payload, payload.token)
			.then((response) => {
				const finalPayload = {
					payload : response.data.body[0],
					index: payload.index
				}
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_EXISTING_BRANCH_ADDRESS',{finalPayload});
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAddressOwners = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAddress(payload.payload, payload.token)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_OWNER_ADDRESS',response.data.body[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAddressExistingOwner = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAddress(payload.payload, payload.token)
			.then((response) => {
				const finalPayload = {
					payload : response.data.body[0],
					index: payload.index
				}
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_EXISTING_OWNER_ADDRESS',{finalPayload});
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAddressBod = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAddress(payload.payload, payload.token)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BOD_ADDRESS',response.data.body[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAddressExistingBod = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAddress(payload.payload, payload.token)
			.then((response) => {
				const finalPayload = {
					payload : response.data.body[0],
					index: payload.index
				}
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_EXISTING_BOD_ADDRESS',{finalPayload});
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const dogetUploadedRenewableFiles = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getUploadedRenewableFiles(payload)
			.then((response) => {
				console.log('response')
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUpdateRenewableDocs = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updateRenewableDocs({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteBod = ({commit}, {payload}) => new Promise((resolve,reject) => {
	console.log('here payload')
	console.log(payload)
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.deleteBod({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteBranch = ({commit}, {payload}) => new Promise((resolve,reject) => {
	console.log('here payload')
	console.log(payload)
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.deleteBranch({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteFla = ({commit}, {payload}) => new Promise((resolve,reject) => {
	console.log('here payload')
	console.log(payload)
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.deleteFla({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteOwner = ({commit}, {payload}) => new Promise((resolve,reject) => {
	console.log('here payload')
	console.log(payload)
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.deleteOwner({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doReturnValidation = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		console.log(payload)
		salesProspectingRepository.returnValidation(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Successfully Returned SP! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doReturnOnboarding = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		console.log(payload)
		salesProspectingRepository.returnOnboarding(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Successfully Returned SP! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

// const doGetEmailTriggerSched = ({commit}) => new Promise((resolve,reject) => {
// 	commit('app/SET_IS_LOADING',true,{ root: true });
// 	setTimeout(() => {
// 		salesProspectingRepository.getEmailTriggerSched()
// 			.then((response) => {
// 				console.log(response)
// 				commit('app/SET_IS_LOADING',false,{ root: true });
// 				resolve(response);
// 			})
// 			.catch((err) => {
// 				commit('app/SET_IS_LOADING',false,{ root: true });
// 				reject(err);
// 			});
// 	}, 2000);
// });

const doGetEmailTriggerSched = (context) => new Promise((resolve,reject) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getEmailTriggerSched()
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doUpdateEmailTrigger = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updateEmailTrigger(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doUploadWitness = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.uploadWitness({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadSignatory = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.uploadSignatory({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUpdateSignatory = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updateSignatory({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteWitness = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.deleteWitness({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetProvince = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProvince(payload)
			.then((response) => {
				let province = []
				Object.values(response.data.body).forEach(val => province.push(val.name))
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_PROVINCE',province);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetBusinessAddRegion = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProvinceDetails(payload.token)
			.then((response) => {
				let region = []
				response.data.body.map(item => {
					if (item.brp_id_name == payload.payload){
						region.push(item.br_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BUSINESSADD_REGION',region[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetBusinessAddCity = ({commit}, {finalPayload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(finalPayload.payload, finalPayload.token)
			.then((response) => {
				let city = []
				response.data.body.map(item => {
					if (!city.includes(item.brpc_id_name)){
						city.push(item.brpc_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BUSINESSADD_CITY',city);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetBusinessAddZip = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(payload.payload, payload.token)
			.then((response) => {
				console.log(response)
				let zip = []
				response.data.body.map(item => {
					if (item.brpc_id_name == payload.city){
						zip.push(item.zipcode)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BUSINESSADD_ZIP',zip);
				resolve(response);
				console.log(zip)
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetMailingBusinessAddRegion = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProvinceDetails(payload.token)
			.then((response) => {
				let region = []
				response.data.body.map(item => {
					if (item.brp_id_name == payload.payload){
						region.push(item.br_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_MAILINGBUSINESSADD_REGION',region[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetMailingBusinessAddCity = ({commit}, {finalPayload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(finalPayload.payload, finalPayload.token)
			.then((response) => {
				let city = []
				response.data.body.map(item => {
					if (!city.includes(item.brpc_id_name)){
						city.push(item.brpc_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_MAILINGBUSINESSADD_CITY',city);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});


const doGetMailingBusinessAddZip = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(payload.payload, payload.token)
			.then((response) => {
				console.log(response)
				let zip = []
				response.data.body.map(item => {
					if (item.brpc_id_name == payload.city){
						zip.push(item.zipcode)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_MAILINGBUSINESSADD_ZIP',zip);
				resolve(response);
				console.log(zip)
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetPrimaryContactRegion = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProvinceDetails(payload.token)
			.then((response) => {
				let region = []
				response.data.body.map(item => {
					if (item.brp_id_name == payload.payload){
						region.push(item.br_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_PRIMARYCONTACT_REGION',region[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGePrimaryContactCity = ({commit}, {finalPayload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(finalPayload.payload, finalPayload.token)
			.then((response) => {
				let city = []
				response.data.body.map(item => {
					if (!city.includes(item.brpc_id_name)){
						city.push(item.brpc_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_PRIMARYCONTACT_CITY',city);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetPrimaryContactZip = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(payload.payload, payload.token)
			.then((response) => {
				console.log(response)
				let zip = []
				response.data.body.map(item => {
					if (item.brpc_id_name == payload.city){
						zip.push(item.zipcode)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_PRIMARYCONTACT_ZIP',zip);
				resolve(response);
				console.log(zip)
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetComplianceRegion = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProvinceDetails(payload.token)
			.then((response) => {
				let region = []
				response.data.body.map(item => {
					if (item.brp_id_name == payload.payload){
						region.push(item.br_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_COMPLIANCE_REGION',region[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGeComplianceCity = ({commit}, {finalPayload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(finalPayload.payload, finalPayload.token)
			.then((response) => {
				let city = []
				response.data.body.map(item => {
					if (!city.includes(item.brpc_id_name)){
						city.push(item.brpc_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_COMPLIANCE_CITY',city);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetComplianceZip = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(payload.payload, payload.token)
			.then((response) => {
				console.log(response)
				let zip = []
				response.data.body.map(item => {
					if (item.brpc_id_name == payload.city){
						zip.push(item.zipcode)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_COMPLIANCE_ZIP',zip);
				resolve(response);
				console.log(zip)
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetBranchesRegion = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProvinceDetails(payload.token)
			.then((response) => {
				let region = []
				response.data.body.map(item => {
					if (item.brp_id_name == payload.payload){
						region.push(item.br_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BRANCHES_REGION',region[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGeBranchesCity = ({commit}, {finalPayload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(finalPayload.payload, finalPayload.token)
			.then((response) => {
				let city = []
				response.data.body.map(item => {
					if (!city.includes(item.brpc_id_name)){
						city.push(item.brpc_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BRANCHES_CITY',city);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetBranchesZip = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(payload.payload, payload.token)
			.then((response) => {
				console.log(response)
				let zip = []
				response.data.body.map(item => {
					if (item.brpc_id_name == payload.city){
						zip.push(item.zipcode)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BRANCHES_ZIP',zip);
				resolve(response);
				console.log(zip)
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetExistingBranchesRegion = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProvinceDetails(payload.token)
			.then((response) => {
				let region = []
				response.data.body.map(item => {
					if (item.brp_id_name == payload.payload){
						region.push(item.br_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				const finalPayload = {
					payload : region[0],
					index: payload.index
				}
				commit('SET_EXISTINGBRANCHES_REGION',{finalPayload});
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetExistingBranchesRegionMailing = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProvinceDetails(payload.token)
			.then((response) => {
				let region = []
				response.data.body.map(item => {
					if (item.brp_id_name == payload.payload){
						region.push(item.br_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				const finalPayload = {
					payload : region[0],
					index: payload.index
				}
				commit('SET_EXISTINGBRANCHES_REGION_MAILING',{finalPayload});
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetExistingBranchesCity = ({commit}, {finalPayload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(finalPayload.payload, finalPayload.token)
			.then((response) => {
				let city = []
				response.data.body.map(item => {
					if (!city.includes(item.brpc_id_name)){
						city.push(item.brpc_id_name)
					}
				})
				let payload = {
					payload : city,
					index : finalPayload.index
				}
				commit('SET_EXISTINGBRANCHES_CITY', {payload});
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetExistingBranchesCityMailing = ({commit}, {finalPayload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(finalPayload.payload, finalPayload.token)
			.then((response) => {
				let city = []
				response.data.body.map(item => {
					if (!city.includes(item.brpc_id_name)){
						city.push(item.brpc_id_name)
					}
				})
				let payload = {
					payload : city,
					index : finalPayload.index
				}
				commit('SET_EXISTINGBRANCHES_CITY_MAILING', {payload});
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetExistingBranchesZip = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(payload.payload, payload.token)
			.then((response) => {
				console.log(response)
				let zip = []
				console.log(payload.index)
				response.data.body.map(item => {
					if (item.brpc_id_name == payload.city){
						zip.push(item.zipcode)
					}
				})
				let finalPayload = {
					payload : zip,
					index : payload.index
				}
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_EXISTINGBRANCHES_ZIP',{finalPayload});
				resolve(response);
				console.log(zip)
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetExistingBranchesZipMailing = ({commit}, {payload}) => new Promise((resolve,reject) => {
	console.log(payload)
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(payload.payload, payload.token)
			.then((response) => {
				console.log(response)
				let zip = []
				console.log(payload.index)
				response.data.body.map(item => {
					if (item.brpc_id_name == payload.city){
						zip.push(item.zipcode)
					}
				})
				let finalPayload = {
					payload : zip,
					index : payload.index
				}
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_EXISTINGBRANCHES_ZIP_MAILING',{finalPayload});
				resolve(response);
				console.log(zip)
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});


const doGetOwnersRegion = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProvinceDetails(payload.token)
			.then((response) => {
				let region = []
				response.data.body.map(item => {
					if (item.brp_id_name == payload.payload){
						region.push(item.br_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_OWNERS_REGION',region[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetOwnersCity = ({commit}, {finalPayload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(finalPayload.payload, finalPayload.token)
			.then((response) => {
				let city = []
				response.data.body.map(item => {
					if (!city.includes(item.brpc_id_name)){
						city.push(item.brpc_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_OWNERS_CITY',city);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetOwnersZip = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(payload.payload, payload.token)
			.then((response) => {
				console.log(response)
				let zip = []
				response.data.body.map(item => {
					if (item.brpc_id_name == payload.city){
						zip.push(item.zipcode)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_OWNERS_ZIP',zip);
				resolve(response);
				console.log(zip)
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetExistingOwnersRegion = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProvinceDetails(payload.token)
			.then((response) => {
				let region = []
				response.data.body.map(item => {
					if (item.brp_id_name == payload.payload){
						region.push(item.br_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				const finalPayload = {
					payload : region[0],
					index: payload.index
				}
				commit('SET_EXISTINGOWNERS_REGION',{finalPayload});
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetExistingOwnersCity = ({commit}, {finalPayload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(finalPayload.payload, finalPayload.token)
			.then((response) => {
				let city = []
				response.data.body.map(item => {
					if (!city.includes(item.brpc_id_name)){
						city.push(item.brpc_id_name)
					}
				})
				let payload = {
					payload : city,
					index : finalPayload.index
				}
				commit('SET_EXISTINGOWNERS_CITY', {payload});
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetExistingOwnersZip = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(payload.payload, payload.token)
			.then((response) => {
				console.log(response)
				let zip = []
				console.log(payload.index)
				response.data.body.map(item => {
					if (item.brpc_id_name == payload.city){
						zip.push(item.zipcode)
					}
				})
				let finalPayload = {
					payload : zip,
					index : payload.index
				}
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_EXISTINGOWNERS_ZIP',{finalPayload});
				resolve(response);
				console.log(zip)
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetBodRegion = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProvinceDetails(payload.token)
			.then((response) => {
				let region = []
				response.data.body.map(item => {
					if (item.brp_id_name == payload.payload){
						region.push(item.br_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BOD_REGION',region[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetBodCity = ({commit}, {finalPayload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(finalPayload.payload, finalPayload.token)
			.then((response) => {
				let city = []
				response.data.body.map(item => {
					if (!city.includes(item.brpc_id_name)){
						city.push(item.brpc_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BOD_CITY',city);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetBodZip = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(payload.payload, payload.token)
			.then((response) => {
				console.log(response)
				let zip = []
				response.data.body.map(item => {
					if (item.brpc_id_name == payload.city){
						zip.push(item.zipcode)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BOD_ZIP',zip);
				resolve(response);
				console.log(zip)
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});


const doGetExistingBodRegion = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProvinceDetails(payload.token)
			.then((response) => {
				let region = []
				response.data.body.map(item => {
					if (item.brp_id_name == payload.payload){
						region.push(item.br_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				const finalPayload = {
					payload : region[0],
					index: payload.index
				}
				commit('SET_EXISTINGBOD_REGION',{finalPayload});
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetExistingBodCity = ({commit}, {finalPayload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(finalPayload.payload, finalPayload.token)
			.then((response) => {
				let city = []
				response.data.body.map(item => {
					if (!city.includes(item.brpc_id_name)){
						city.push(item.brpc_id_name)
					}
				})
				let payload = {
					payload : city,
					index : finalPayload.index
				}
				commit('SET_EXISTINGBOD_CITY', {payload});
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetExistingBodZip = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(payload.payload, payload.token)
			.then((response) => {
				console.log(response)
				let zip = []
				console.log(payload.index)
				response.data.body.map(item => {
					if (item.brpc_id_name == payload.city){
						zip.push(item.zipcode)
					}
				})
				let finalPayload = {
					payload : zip,
					index : payload.index
				}
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_EXISTINGBOD_ZIP',{finalPayload});
				resolve(response);
				console.log(zip)
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadAvp = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.uploadAvp({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doReturnSp_Eva  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.returnSp_Eva({payload})
			.then((response) => {
				console.log(response)
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				// commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetLogs  = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getlogs()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doFileTermination  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.fileTermination({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doApproveTermination  = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.approveTermination(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				this.$store.dispatch('app/setSnackBar',{'status': true,'message':'SP Terminated!','messagetype':'success'});
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doRejectTermination  = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.rejectTermination(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Returned Successfully!','messagetype':'success'});
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doRequestCmap  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.requestCmap({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetDashboardData  = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING_DASHBOARD',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getDashboardData()
			.then((response) => {
				commit('app/SET_IS_LOADING_DASHBOARD',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING_DASHBOARD',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUpdateFaq  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updateFaq({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUpdateStatus  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updateStatus({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUpdateAcronym  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.updateAcronym({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doAddFaq  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.addFaq({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doAddStatus  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.addStatus({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doAddAcronym  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.addAcronym({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDelete  = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.deleteItem(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteAcronym  = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.deleteAcronym(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});
const doDeleteStatus  = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.deleteStatus(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDownloadFile  = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.downloadFile(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetlAllUsers  = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAllUsers()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doAddCluserHead  = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.addCluserHead(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAllCluster  = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAllCluster()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doAddRoh  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.addRoh({payload})
			.then((response) => {
				console.log(response)
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				console.log(err)
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doADdSas  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.addSas({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteSas  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	console.log(payload)
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.deleteSas({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteRoh  = ({commit}, {payload}) => new Promise((resolve,reject) => {
	console.log(payload)
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.deleteRoh({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteCluster  = ({commit}, payload) => new Promise((resolve,reject) => {
	console.log(payload)
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.deleteCluster(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetDashboard  = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING_DASHBOARD',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getDashboard()
			.then((response) => {
				commit('app/SET_IS_LOADING_DASHBOARD',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING_DASHBOARD',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetProfilingData  = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProfilingData()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetNotHandledBranch  = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getNotHandledBranch()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});


const doAddHandledEntity = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.addHandledEntity({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteHandledEntity  = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.deleteHandledEntity(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doAddNewEntity = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.addNewEntity({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteNewHandledEntity  = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.deleteNewHandledEntity(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doEditNewEntity = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.editNewEntity({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadProfile = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.uploadProfile({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const changePageBranch = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.pageBranch({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetStatusCode = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getStatusCode(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetAllMerchantDetails = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getAllMerchantDetails(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetEnrolledDetails = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getEnrolledDetails(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const changePageEnrolledBranch = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.pageEnrolledBranch({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetUnenrolledDetails = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getUnenrolledDetails(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const changePageUnenrolledBranch = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.pageUnenrolledBranch({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadSasEntity = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.uploadSasEntity({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadSasLocation = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.uploadSasLocation({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doFileSuspensionEntity = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.fileSuspensionEntity({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doFileSuspensionLocation = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.fileSuspensionLocation({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doFileDeactivationEntity = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.fileDeactivationEntity({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doFileDeactivationLocation = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.fileDeactivationLocation({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetLocationEntity = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getLocationEntity()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetSuspensionForApproval = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getSuspensionForApproval()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetDeactivationForApproval = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getDeactivationForApproval()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDisapproveEntity = ({commit} , {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.disapproveEntity({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDisapproveLocation = ({commit} , {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.disapproveLocation({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doApproveEntity = ({commit} , payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.approveEntity(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doApproveLocation = ({commit} , payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.approveLocation(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetReactivationList = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getReactivationList()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});


const doFileReactivationEntity = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.fileReactivationEntity({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doFileReactivationLocation = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.fileReactivationLocation({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetArchivedList = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getArchivedList()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetBranchesMailingRegion = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getProvinceDetails(payload.token)
			.then((response) => {
				let region = []
				response.data.body.map(item => {
					if (item.brp_id_name == payload.payload){
						region.push(item.br_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BRANCHES_MAILING_REGION',region[0]);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGeBranchesMailingCity = ({commit}, {finalPayload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(finalPayload.payload, finalPayload.token)
			.then((response) => {
				let city = []
				response.data.body.map(item => {
					if (!city.includes(item.brpc_id_name)){
						city.push(item.brpc_id_name)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BRANCHES_MAILING_CITY',city);
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});


const doGetBranchesMailingZip = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.getCity(payload.payload, payload.token)
			.then((response) => {
				console.log(response)
				let zip = []
				response.data.body.map(item => {
					if (item.brpc_id_name == payload.city){
						zip.push(item.zipcode)
					}
				})
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('SET_BRANCHES_MAILING_ZIP',zip);
				resolve(response);
				console.log(zip)
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doRevertStatus = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		salesProspectingRepository.revertStatus(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

export default{
	doGetAllSalesProspecting,
	doGetAllListSalesProspecting,
    doCreateSPNumber,
	doSaveSalesProspecting,
	doGetSalesProspecting,
	doUploadFiles,
	doGetUploadedFiles,
	doRetrieveUploaded,
	doApproveSPByEval,
	doDisapproveSPByPresident,
	doApproveSPByPres,
	doGetCountries,
	doUpdateSalesProspecting,
	doApproveSPByOnboarding,
	doUploadOwner,
	doUpdateOwner,
	doInitialUpload,
	doUploadBranch,
	doUpdateBranch,
	doCreateComment_Eva,
	doCreateComment_Ceo,
	doUploadBSP,
	doGenerateMoa,
	doUploadBod,
	doUpdateBod,
	doApproveSPByValidation,
	doGetZip,
	doGetAddressBusinessAdd,
	doGetAddressContactInfo,
	doGetAddressCompliance,
	doGetAddressBranch,
	doGetAddressOwners,
	doGetAddressBod,
	doGetAddressExistingBranch,
	doGetAddressExistingOwner,
	doGetAddressExistingBod,
	dogetUploadedRenewableFiles,
	doUpdateRenewableDocs,
	doDeleteBod,
	doDeleteBranch,
	doDeleteFla,
	doDeleteOwner,
	doReturnValidation,
	doReturnOnboarding,
	doGetEmailTriggerSched,
	doUpdateEmailTrigger,
	doUploadWitness,
	doUploadSignatory,
	doUpdateSignatory,
	doDeleteWitness,
	doGetMailingAddressBusinessAdd,
	doGetProvince,
	doGetBusinessAddRegion,
	doGetBusinessAddCity,
	doGetBusinessAddZip,
	doGetMailingBusinessAddRegion,
	doGetMailingBusinessAddCity,
	doGetMailingBusinessAddZip,
	doGetPrimaryContactRegion,
	doGePrimaryContactCity,
	doGetPrimaryContactZip,
	doGetComplianceRegion,
	doGeComplianceCity,
	doGetComplianceZip,
	doGetBranchesRegion,
	doGeBranchesCity,
	doGetBranchesZip,
	doGetExistingBranchesRegion,
	doGetExistingBranchesCity,
	doGetExistingBranchesZip,
	doGetOwnersRegion,
	doGetOwnersCity,
	doGetOwnersZip,
	doGetExistingOwnersRegion,
	doGetExistingOwnersCity,
	doGetExistingOwnersZip,
	doGetBodRegion,
	doGetBodCity,
	doGetBodZip,
	doGetExistingBodRegion,
	doGetExistingBodCity,
	doGetExistingBodZip,
	doUploadAvp,
	doReturnSp_Eva,
	doGetLogs,
	doFileTermination,
	doGetAllTerminationPending,
	doApproveTermination,
	doRejectTermination,
	doRequestCmap,
	doGetDashboardData,
	doUpdateFaq,
	doAddFaq,
	doAddStatus,
	doDelete,
	doDeleteStatus,
	doUpdateStatus,
	doAddAcronym,
	doUpdateAcronym,
	doDeleteAcronym,
	doDownloadFile,
	doGetAllowedBsp,
	doUpdateBusinessInformation,
	doGetlAllUsers,
	doAddCluserHead,
	doGetAllCluster,
	doAddRoh,
	doADdSas,
	doDeleteSas,
	doDeleteCluster,
	doDeleteRoh,
	doGetDashboard,
	doGetProfilingData,
	doGetNotHandledBranch,
	doAddHandledEntity,
	doDeleteHandledEntity,
	doAddNewEntity,
	doDeleteNewHandledEntity,
	doEditNewEntity,
	doUploadProfile,
	changePageBranch,
	doGetStatusCode,
	doGetAllMerchantDetails,
	doGetEnrolledDetails,
	changePageEnrolledBranch,
	doGetUnenrolledDetails,
	changePageUnenrolledBranch,
	doUploadSasEntity,
	doUploadSasLocation,
	doFileSuspensionEntity,
	doFileSuspensionLocation,
	doGetLocationEntity,
	doFileDeactivationEntity,
	doFileDeactivationLocation,
	doGetSuspensionForApproval,
	doGetDeactivationForApproval,
	doDisapproveEntity,
	doDisapproveLocation,
	doApproveEntity,
	doApproveLocation,
	doGetReactivationList,
	doFileReactivationEntity,
	doFileReactivationLocation,
	doGetArchivedList,
	doGetBranchesMailingRegion,
	doGeBranchesMailingCity,
	doGetBranchesMailingZip,
	doGetExistingBranchesRegionMailing,
	doGetExistingBranchesCityMailing,
	doGetExistingBranchesZipMailing,
	doRevertStatus,
	doUpdateComplianceDocuments,
	doUpdatePrimaryContactInformationDocuments,
	doReAccreditationSaveSalesProspecting,
	// doReAccreditationReturnValidationSalesProspecting,
	// doReAccreditationReturnOnBoardingSalesProspecting,
	doUploadRiskTool,
	doUpdateExistingSalesProspecting
};